import * as React from "react"
import { Link } from "gatsby"
import axios from "axios"

import PageHeader from "../components/pageheader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/search.module.css"
import hero from "../images/search-hero.jpg"
import { useEffect } from "react"
import { useState } from "react"

const SEARCH_BY_KEYWORD = `
query Search(
    $search: String,
    $first: Int,
    $after: String,
    $before: String,
    $last: Int)
 {
  contentNodes(
    where: {search: $search, language: EN, contentTypes: [POST, PROJECT]}
    first: $first
    after: $after
    before: $before
    last: $last
  ) {
    nodes {
      __typename
      ... on Post {
        title
        slug
        excerpt
      }
      ... on Project {
        title
        slug
        propertyTypes {
          nodes {
            parentId
            slug
            name
          }
        }
        blocks {
          name
          ... on AcfCoverMainBlock {
            coverMain {
              img {
                mediaItemUrl
              }
              imgOverlay
              label
              title
              text
              buttons {
                text
                link {
                  target
                  url
                }
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}`

const PaginationBlock = ({
    pagination,
    hasNextPage,
    hasPreviousPage,
    setPage,
}) => {
    const { totalPage, currPage } = pagination
    const pages = Array.from({ length: totalPage }, (_v, i) => i)
    return (
        <div className="mt-12 mb-4 flex justify-center items-center">
            <ul className={`text-center my-16 ${styles.pagination}`}>
                <li
                    className={styles.prev}
                    style={!hasPreviousPage ? { display: "none" } : {}}
                >
                    <button
                        className={`inline-block mr-2 ${styles.btn} ${styles.prevBtn}`}
                        onClick={e => {
                            hasPreviousPage ? setPage(e, currPage - 1) : ""
                        }}
                    >
                        prev
                    </button>
                </li>
                {pages.map((v, i) => {
                    const page = v + 1
                    if (v + 1 == currPage) {
                        return (
                            <li key={i} className={styles.pageItem}>
                                <span className="">{page}</span>
                            </li>
                        )
                    } else {
                        return (
                            <li key={i} className={styles.pageItem}>
                                <a href="#" onClick={e => setPage(e, page)}>
                                    {page}
                                </a>
                            </li>
                        )
                    }
                })}
                <li
                    className={styles.next}
                    style={!hasNextPage ? { display: "none" } : {}}
                >
                    <button
                        className={`inline-block mr-2 ${styles.btn} ${styles.nextBtn}`}
                        onClick={e => {
                            hasNextPage ? setPage(e, currPage + 1) : ""
                        }}
                    >
                        next
                    </button>
                </li>
            </ul>
        </div>
    )
}

const SearchPage = ({ location }) => {
    const params = new URLSearchParams(location.search)
    const keyword = params.get("q")
    const limit = 100
    const offset = 0
    const perPage = 10
    const [totalResult, setTotalResult] = useState(null)
    const [result, setResult] = useState()
    const [pagination, setPagination] = useState({
        totalPage: 0,
        offset,
        limit: perPage,
        currPage: 1,
    })

    const getResult = async ({ keyword, first, after, before, last }) => {
        var data = JSON.stringify({
            query: SEARCH_BY_KEYWORD,
            variables: { search: keyword, first, after, before, last },
        })

        var config = {
            method: "post",
            url: process.env.WP_URL,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        }

        try {
            const response = await axios(config)
            const nodes = response?.data?.data?.contentNodes?.nodes
            const filterNodes = []
            nodes.forEach(node => {
                if (node.__typename == "Project") {
                    if (node.propertyTypes.nodes.length > 0) {
                        filterNodes.push(node)
                    }
                } else {
                    filterNodes.push(node)
                }
            })
            const numPages = Math.ceil(filterNodes.length / perPage)
            setResult(filterNodes)
            setTotalResult(filterNodes.length)
            setPagination({
                ...pagination,
                totalPage: numPages,
            })
        } catch (e) {
            console.log(error)
        }
    }

    const hasPreviousPage = () => {
        if (pagination.offset > offset) {
            return true
        } else {
            return false
        }
    }

    const hasNextPage = () => {
        if (pagination.limit < totalResult) {
            return true
        } else {
            return false
        }
    }

    const setPage = async (e, page) => {
        e.preventDefault()
        setPagination({
            ...pagination,
            currPage: page,
            offset: (page - 1) * perPage,
            limit: page * perPage,
        })
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    // init data
    useEffect(() => {
        getResult({ keyword, first: limit })
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <Layout isWhiteBg>
            <Seo title={`Search Result: "${keyword || "-"}"`} />
            <PageHeader
                image={hero}
                breadcrumbs={[
                    {
                        label: `Search Result: "${keyword || "-"}"`,
                        link: null,
                    },
                ]}
            >
                {<></>}
            </PageHeader>
            <div className="px-4">
                <div className="container mx-auto">
                    <form
                        action="/search"
                        method="GET"
                        className={`w-full flex py-4 mb-4 justify-between items-center ${styles.form}`}
                    >
                        <button className={styles.searchBtn}>Search</button>
                        <input
                            placeholder="Search"
                            type="text"
                            name="q"
                            className={`flex-1 pl-4 ml-4 ${styles.formInput}`}
                            defaultValue={keyword}
                        />
                    </form>
                </div>
            </div>
            <div className="container mx-auto">
                {!result ||
                    (result.length === 0 && (
                        <div className="px-4 py-32">
                            <div
                                className={`text-center mt-4 ${styles.emptyResult}`}
                            >
                                Sorry, No result(s) found.
                            </div>
                        </div>
                    ))}
                <div className="px-5 lg:px-16 pt-16 py-8">
                    {result &&
                        result
                            .slice(pagination.offset, pagination.limit)
                            .map((item, i) => {
                                let link
                                switch (item?.__typename) {
                                    case "Project":
                                        return (
                                            <article
                                                key={i}
                                                className={`text-left pb-12 mb-12 ${styles.article}`}
                                            >
                                                {item?.propertyTypes?.nodes.map(
                                                    (p, k) => {
                                                        if (
                                                            p?.parentId == null
                                                        ) {
                                                            link = `/developments/${p?.slug}`
                                                            return (
                                                                <Link
                                                                    key={k}
                                                                    className={`text-sm ${styles.parentLink}`}
                                                                    to={link}
                                                                >
                                                                    {p?.name}
                                                                </Link>
                                                            )
                                                        }
                                                    }
                                                )}
                                                <h3 className="font-serif text-4xl my-4 font-bold">
                                                    <Link
                                                        to={`${link}/${item?.slug}`}
                                                    >
                                                        {item?.title}
                                                    </Link>
                                                </h3>
                                                {item?.blocks.map((b, k) => {
                                                    if (
                                                        b?.name ===
                                                        "acf/cover-main"
                                                    ) {
                                                        const coverMain =
                                                            b?.coverMain
                                                        return (
                                                            <p
                                                                key={k}
                                                                className="mb-4"
                                                            >
                                                                {coverMain?.text.substring(
                                                                    0,
                                                                    165
                                                                ) + "..."}
                                                            </p>
                                                        )
                                                    }
                                                })}
                                                <Link
                                                    className={styles.learnMore}
                                                    to={`${link}/${item?.slug}`}
                                                >
                                                    Learn More
                                                </Link>
                                            </article>
                                        )
                                    case "Post":
                                        link = `/news/${item?.slug}`
                                        return (
                                            <article
                                                key={i}
                                                className={`text-left pb-12 mb-12 ${styles.article}`}
                                            >
                                                <Link
                                                    className={`text-sm ${styles.parentLink}`}
                                                    to="/news"
                                                >
                                                    News
                                                </Link>
                                                <h3 className="font-serif text-4xl my-4 font-bold">
                                                    <Link to={link}>
                                                        {item?.title}
                                                    </Link>
                                                </h3>
                                                <div
                                                    className="mb-4"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            item?.excerpt.substring(
                                                                0,
                                                                165
                                                            ) + "...",
                                                    }}
                                                ></div>
                                                <Link
                                                    className={styles.learnMore}
                                                    to={link}
                                                >
                                                    Learn More
                                                </Link>
                                            </article>
                                        )
                                }
                            })}
                    {result && result.length > 0 && (
                        <PaginationBlock
                            pagination={pagination}
                            hasNextPage={hasNextPage()}
                            hasPreviousPage={hasPreviousPage()}
                            setPage={setPage}
                        />
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default SearchPage
