// extracted by mini-css-extract-plugin
export var form = "search-module--form--aHcow";
export var searchBtn = "search-module--searchBtn--2miuY";
export var formInput = "search-module--formInput--exiIW";
export var parentLink = "search-module--parentLink--10RcU";
export var article = "search-module--article--2vI-y";
export var learnMore = "search-module--learnMore--1wasl";
export var emptyResult = "search-module--emptyResult--3pEJs";
export var pagination = "search-module--pagination--AJlHW";
export var pageItem = "search-module--pageItem--2xluw";
export var prev = "search-module--prev--Z5fbN";
export var next = "search-module--next--1Qi0l";
export var btn = "search-module--btn--2Ji_f";
export var prevBtn = "search-module--prevBtn--1B6Iq";
export var nextBtn = "search-module--nextBtn--fcMfg";